import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import RoomsContext from '#context/rooms';
import Loader from '#components/loader.jsx';

function RoomsList() {
	const location = useLocation();
	const { list, get_list, api } = useContext(RoomsContext);

	const [dragged_item, set_dragged_item] = useState(null);
	const [current_list, set_current_list] = useState();

	const drag_enter = (e, i) => {
		e.preventDefault();
		e.stopPropagation();

		const new_list = [...current_list];
		const content = new_list[dragged_item];

		new_list.splice(dragged_item, 1);
		new_list.splice(i, 0, content);

		set_dragged_item(i);
		set_current_list(new_list);
	};

	const drag_end = () => {
		set_dragged_item();
		api.update_indexes(current_list);
	};

	useEffect(() => {
		get_list();
	}, []);

	useEffect(() => {
		set_current_list(list);
	}, [list]);

	if (!current_list) return <Loader />;

	return (
		<>
			<div className="list_head">
				<p>Room Types</p>
				<p>Qty</p>
			</div>
			<ul className="list">
				{current_list.map(({ name, _id, count }, i) => {
					let cls = '';
					let next = current_list[i + 1];
					if (next && location.hash.includes(next._id)) cls = ' borderless';

					return (
						<li
							key={i}
							className={`${location.hash.includes(_id) ? 'active' : ''}${dragged_item === i ? ' dragging' : ''}${cls}`}
							draggable="true"
							onDragStart={() => set_dragged_item(i)}
							onDragEnter={(e) => drag_enter(e, i)}
							onDragOver={(e) => {
								e.preventDefault();
								e.stopPropagation();
							}}
							onDragEnd={drag_end}
						>
							<p>{name}</p>
							<p>{count}</p>
							<div className="actions">
								<a className="icon border arrow_right_requests" href={`#room_${_id}`}></a>
							</div>
						</li>
					);
				})}
			</ul>
		</>
	);
}

function Header() {
	return (
		<div className="header_block">
			<h1>Rooms</h1>
			<a className="button approve" href="#room">
				+Add Room Type
			</a>
		</div>
	);
}

function RoomsView() {
	return (
		<section className="dashboard_list rooms_list">
			<Header />
			<RoomsList />
		</section>
	);
}

export default RoomsView;
