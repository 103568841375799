import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '#components/loader.jsx';
import DropdownCalendar from '#components/dropdown_calendar.jsx';
import { DateTime } from 'luxon';

import BookingsContext from '#context/bookings.jsx';
import UserContext from '#context/user.jsx';

import rooms_api from '#api/rooms.js';

function generate_dates({ year, month }, from, to, max) {
	const from_t = from.year * 100 + from.month; //Can use timestamps, but this is simpler
	const current_t = year * 100 + month;
	const to_t = to.year * 100 + to.month;

	const dates = {};

	if (current_t < from_t || current_t > to_t) {
		dates;
	} else if (current_t > from_t && current_t < to_t) {
		for (let i = 0; i < 31; i++) dates[i] = 'demonstration';
	} else if (current_t === from_t) {
		dates[from.day] = 'active';
		const from_date = from.day + 1;
		if (from_date > from.daysInMonth) return dates;
		if (from_t === to_t) {
			for (let i = from_date; i < to.day; i++) dates[i] = 'demonstration';
			dates[to.day] = 'active';
		} else {
			for (let i = from_date; i <= from.daysInMonth; i++) dates[i] = 'demonstration';
		}
	} else if (current_t === to_t) {
		if (from_t === to_t) {
			dates[from.day] = 'active';
			const from_date = from.day + 1;
			if (from_date > from.daysInMonth) return dates;
			for (let i = from_date; i < to.day; i++) dates[i] = 'demonstration';
			dates[to.day] = 'active';
		} else {
			for (let i = 1; i <= to.day; i++) dates[i] = 'demonstration';
			dates[to.day] = 'active';
		}
	}

	if (year === max.year && month === max.month) for (let i = max.day + 1; i <= max.daysInMonth; i++) dates[i] = 'unavailable';

	return dates;
}

function _pie_data(data) {
	let total = 0;
	let offset = 0;

	data.forEach((item) => (total += item));

	if (data.filter((item) => item !== 0).length === 1) {
		return <circle cx="90" cy="90" r="90" fill={_colors[data.indexOf(total)]} />;
	}

	return data.map((item, i) => {
		const radians = ((item / total) * 360 * Math.PI) / 180;
		const offset2 = (offset / total) * 360;

		const x = 90 + Math.sin(radians) * 90;
		const y = 90 - Math.cos(radians) * 90;
		const la = radians > Math.PI ? 1 : 0;

		offset += item;

		return (
			<g key={i} transform={`rotate(${offset2}, 90, 90)`}>
				<path d={`M90 90 L90 0 A90 90 0 ${la} 1 ${x} ${y}Z`} fill={_colors[i]} />
			</g>
		);
	});
}

const _colors = ['#b9d6f2', '#629ed6', '#0079d0', '#0353a4', '#417fb1', '#3b506c', '#061a40'];

const _days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

function AnalyticsView() {
	const bookings_context = useContext(BookingsContext);
	const { session } = useContext(UserContext);
	const [bookings, set_bookings] = useState(undefined);
	const [rooms, set_rooms] = useState(undefined);

	let { from = DateTime.now().set({ day: 1 }).toFormat('yyyy-MM-dd'), to = DateTime.now().toFormat('yyyy-MM-dd') } = useParams();

	const [dates_cls, set_dates_cls] = useState({});
	const navigate = useNavigate();

	useEffect(() => {
		set_bookings();
		(async () => {
			set_bookings(await bookings_context.api.analytics({ from: from.toFormat('yyyy-MM-dd'), to: to.toFormat('yyyy-MM-dd') }));
		})();
	}, [from, to]);

	useEffect(() => {
		(async () => {
			set_rooms(await rooms_api.list_all({ session }));
		})();
	}, [1]);

	from = DateTime.fromISO(from);
	to = DateTime.fromISO(to);

	if (!bookings || !rooms) return <Loader />;

	const hours = {
		total: 0,
		setup: 0,
		session: 0,
		cleanup: 0,
	};

	const lengths = {};

	const attendees = [0, 0, 0, 0, 0, 0];

	let total_attendees = 0;

	const days = [0, 0, 0, 0, 0, 0, 0, 0];

	const users = new Map();

	const status = {
		total: 0,
		approved: 0,
		cancelled: 0,
		declined: 0,
		performed: 0,
	};

	let rooms_map = new Map();

	rooms.forEach((room) => rooms_map.set(`${room.roomType} ${room.name}`, { sessions: 0, hours: 0 }));

	bookings.forEach((booking) => {
		if (booking.result === 'cancelled') console.log(booking);
		if ((booking.blockedTime || booking.status === 'blocked') && !booking.analytics) return;

		if (booking.status === 'pending') return;

		status.total += 1;
		if (booking.result === 'cancelled') {
			console.log('cancelled', booking);
			status.cancelled += 1;
		} else if (booking.status === 'approved' || booking.result === 'completed') {
			status.approved += 1;
		} else if (booking.result === 'declined') {
			status.declined += 1;
		}

		if (booking.result !== 'completed') return;

		status.performed += 1;

		if (!users.has(booking.user)) users.set(booking.user, 0);
		users.set(booking.user, users.get(booking.user) + 1);

		const times = booking.schedule.times;
		hours.total += times[2].end - times[0].start;
		if (!(booking.blockedTime || booking.status === 'blocked')) hours.setup += times[0].end - times[0].start;
		hours.session += times[1].end - times[1].start;
		if (!(booking.blockedTime || booking.status === 'blocked')) hours.cleanup += times[2].end - times[2].start;

		let length = Math.round((times[1].end - times[1].start) / 2) - 1;
		if (length < 0) length = 0;
		if (!lengths[length]) lengths[length] = 0;
		lengths[length] += 1;

		let att = parseInt(booking.requestInformation.Attendees);
		if (isNaN(att)) {
			att = booking.requestInformation.Attendees.match(/\d+/gi);
			if (att) {
				att = parseInt(att[0]);
				if (isNaN(att)) att = 0;
			} else {
				att = 0;
			}
		}

		attendees[Math.min(5, Math.ceil(att / 5) - 1)] += 1;

		total_attendees += att;

		days[DateTime.fromMillis(booking.schedule.timestamp, { zone: 'utc' }).weekday] += times[1].end - times[1].start;

		for (let i in booking.schedule.rooms) {
			booking.schedule.rooms[i].forEach((room) => {
				const name = `${room.roomType} ${room.name}`;
				if (!rooms_map.has(name))
					rooms_map.set(name, {
						sessions: 0,
						hours: 0,
					});

				const data = rooms_map.get(name);
				data.sessions += 1;
				data.hours += times[1].end - times[1].start;
			});
		}
	});

	days.shift();

	const days_total = Math.max(
		0.01,
		days.reduce((h, acc) => h + acc, 0)
	);
	const hours_total = Math.max(hours.total, 0.01);
	const status_total = Math.max(status.performed, 0.01);

	const max_length = Math.max(...Object.keys(lengths));

	const lengths_array = new Array();
	for (let i = 0; i < max_length; i++) lengths_array[i] = 0;
	for (let i in lengths) lengths_array[i] = lengths[i];

	rooms_map = Array.from(rooms_map.entries()).map(([name, data]) => ({ name, ...data }));

	const unique_users = users.get(undefined) + users.size - (users.get(undefined) ? 1 : 0) || 0;

	return (
		<section className="dashboard_list analytics">
			<div className="header_block">
				<h1>Analytics</h1>

				<div className="time_period">
					<div>
						<p>From:</p>
						<DropdownCalendar
							selected={from.toFormat('yyyy-MM-dd')}
							selection={from.toFormat('ccc - LLL d, yyyy')}
							maximum={to.toObject({ year: true, month: true })}
							on_month_change={(current) => set_dates_cls(generate_dates(current, from, to, to))}
							dates_cls={dates_cls}
							clb={(current) => navigate(`/dashboard/analytics/${current}/${to.toFormat('yyyy-MM-dd')}`)}
						/>
					</div>

					<div>
						<p>To:</p>
						<DropdownCalendar
							cls="right"
							selected={to.toFormat('yyyy-MM-dd')}
							selection={to.toFormat('ccc - LLL d, yyyy')}
							minimum={from.toObject({ year: true, month: true })}
							maximum={DateTime.now().toObject({ year: true, month: true })}
							on_month_change={(current) => set_dates_cls(generate_dates(current, from, to, DateTime.now()))}
							dates_cls={dates_cls}
							clb={(current) => navigate(`/dashboard/analytics/${from.toFormat('yyyy-MM-dd')}/${current}/`)}
						/>
					</div>
				</div>
			</div>

			<p>Calculations based on approved and completed sessions except for 'Sessions' statistics</p>

			<div className="main_block">
				<div>
					<div className="block">
						<h2>Hours Booked</h2>
						<ul>
							<li className="main">
								<h3>{hours.total}</h3>
								<p>Total Hours Booked</p>
							</li>
							<li>
								<h3>{hours.setup}</h3>
								<p>Set Up Hours</p>
								<span>{+((hours.setup * 100) / hours_total).toFixed(2)}%</span>
							</li>
							<li>
								<h3>{hours.session}</h3>
								<p>Session Hours</p>
								<span>{+((hours.session * 100) / hours_total).toFixed(2)}%</span>
							</li>
							<li>
								<h3>{hours.cleanup}</h3>
								<p>Clean Up Hours</p>
								<span>{+((hours.cleanup * 100) / hours_total).toFixed(2)}%</span>
							</li>
						</ul>
					</div>

					<div className="in_row">
						<div className="block">
							<h2>Sessions</h2>
							<ul>
								<li>
									<h3>{status.approved}</h3>
									<p>Approved</p>
								</li>
								<li>
									<h3>{status.cancelled}</h3>
									<p>Cancelled</p>
								</li>
								<li>
									<h3>{status.declined}</h3>
									<p>Declined</p>
								</li>
							</ul>
						</div>

						<div className="block">
							<h2>Users</h2>
							<ul>
								<li>
									<h3>{unique_users}</h3>
									<p>Unique Users</p>
								</li>
							</ul>
						</div>
					</div>

					<div className="block diagram_box">
						<h2>Day of Week Session Usages</h2>
						<div>
							<svg className="diagram" width="180px" height="180px">
								{_pie_data(days)}
							</svg>
							<ul>
								{days.map((count, day) => (
									<li key={day}>
										<span></span>
										<p>{+((count * 100) / days_total).toFixed(2)}%</p>
										<p>{_days[day]}</p>
									</li>
								))}
							</ul>
						</div>
					</div>

					<div className="block">
						<h2>Room Usage</h2>

						<div>
							<div className="list_head">
								<p>Room Type</p>
								<p>Sessions</p>
								<p>Hours</p>
							</div>

							<ul className="list">
								{rooms_map.map((room, i) => (
									<li key={i}>
										<p>{room.name}</p>
										<p>{room.sessions}</p>
										<p>{room.hours}</p>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>

				<div className="right_side">
					<div className="block">
						<h2>Duration</h2>

						<div>
							<div className="list_head">
								<p>Session Lengths</p>
								<p>Sessions</p>
							</div>

							<ul className="list">
								{lengths_array.map((count, i) => (
									<li key={i}>
										<p>
											{i === 0 ? 0 : i * 2 + 0.5}-{(i + 1) * 2} hours
										</p>
										<p>{count}</p>
										<p>{+((100 * count) / status_total).toFixed(2)}%</p>
									</li>
								))}
							</ul>
						</div>
					</div>

					<div className="block">
						<h2>Attendees</h2>

						<div>
							<div className="list_head">
								<p># of people</p>
								<p>Sessions</p>
							</div>

							<ul className="list">
								<li>
									<p>1-5</p>
									<p>{attendees[0]}</p>
									<p>{+((100 * attendees[0]) / status_total).toFixed(2)}%</p>
								</li>
								<li>
									<p>6-10</p>
									<p>{attendees[1]}</p>
									<p>{+((100 * attendees[1]) / status_total).toFixed(2)}%</p>
								</li>
								<li>
									<p>11-15</p>
									<p>{attendees[2]}</p>
									<p>{+((100 * attendees[2]) / status_total).toFixed(2)}%</p>
								</li>
								<li>
									<p>16-20</p>
									<p>{attendees[3]}</p>
									<p>{+((100 * attendees[3]) / status_total).toFixed(2)}%</p>
								</li>
								<li>
									<p>20-25</p>
									<p>{attendees[4]}</p>
									<p>{+((100 * attendees[4]) / status_total).toFixed(2)}%</p>
								</li>
								<li>
									<p>&gt;25</p>
									<p>{attendees[5]}</p>
									<p>{+((100 * attendees[5]) / status_total).toFixed(2)}%</p>
								</li>
							</ul>
						</div>
					</div>
					<div className="block">
						<h2>Total Attendees</h2>
						<div>
							<h3>{total_attendees}</h3>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default AnalyticsView;
