import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import AssetsContext from '#context/assets';
import Loader from '#components/loader.jsx';

function AssetsList({ type }) {
	const location = useLocation();
	const { list, api } = useContext(AssetsContext);

	const [dragged_item, set_dragged_item] = useState(null);
	const [current_list, set_current_list] = useState();

	const drag_enter = (e, i) => {
		e.preventDefault();
		e.stopPropagation();

		if (!dragged_item) return;

		const item = current_list.find((item) => item._id === dragged_item);

		if (!item) return;
		const current_pos = current_list.indexOf(item);
		if (current_pos === -1) return;

		const new_list = [...current_list];

		new_list.splice(current_pos, 1);
		new_list.splice(i, 0, item);
		set_current_list(new_list);
	};

	const drag_end = () => {
		set_dragged_item();
		api.update_indexes(current_list);
	};

	useEffect(() => {
		if (!list) return set_current_list(list);
		set_current_list(list.filter((el) => el.type === type).sort((a, b) => a.index - b.index));
	}, [list]);

	if (!current_list) return <Loader />;

	return (
		<>
			<div className="list_head">
				<p>Name</p>
				{type === 'equipment' ? <p>Qty</p> : <></>}
			</div>
			<ul className={`list ${type}`}>
				{current_list.map(({ name, _id, ids }, i) => {
					let cls = '';
					let next = current_list[i + 1];
					if (next && location.hash.includes(next._id)) cls = ' borderless';
					return (
						<li
							key={_id}
							className={`${location.hash.includes(_id) ? 'active' : ''}${dragged_item === _id ? ' dragging' : ''}${cls}`}
							draggable="true"
							onDragStart={() => set_dragged_item(_id)}
							onDragEnter={(e) => drag_enter(e, i)}
							onDragOver={(e) => {
								e.preventDefault();
								e.stopPropagation();
							}}
							onDragEnd={drag_end}
						>
							<p>{name}</p>
							{ids ? <p>{ids.length}</p> : <></>}
							<div className="actions">
								<a className="icon border arrow_right_requests" href={`#asset_${_id}`}></a>
							</div>
						</li>
					);
				})}
			</ul>
		</>
	);
}

function Header({ header, label, link }) {
	return (
		<div className="header_block">
			<h1>{header}</h1>
			<a className="button approve" href={link}>
				{label}
			</a>
		</div>
	);
}

function RoomsView() {
	const { get_list } = useContext(AssetsContext);

	useEffect(() => {
		get_list();
	}, []);

	return (
		<section className="dashboard_list rooms_list assets_list">
			<Header header="People" label="+ Add Person" link="#asset_staff" />
			<AssetsList type="staff" />

			<Header header="Equipment" label="+ Add Equipment" link="#asset_equipment" />
			<AssetsList type="equipment" />
		</section>
	);
}

export default RoomsView;
