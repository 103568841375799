import React, { useEffect, useContext, useState } from 'react';
import { DateTime } from 'luxon';
import { Link, useLocation, useParams } from 'react-router-dom';

import Loader from '#components/loader.jsx';
//import NotificationsContenxt from '#context/notifications.jsx';
import BookingsContext from '#context/bookings.jsx';
import MonthPicker from '#components/month_picker.jsx';
import { get_rooms } from '#utils/index.jsx';

import { prettier } from '#components/time_helpers.js';

const results_title = {
	completed: 'Completed',
	cancelled: 'Cancelled',
	declined: 'Declined',
};

const tabs = {
	pending: 'Pending',
	approved: 'Approved',
	completed: 'Completed',
};

const def_tab = Object.keys(tabs)[0];

const get_link = (status, period) => `/dashboard/requests/${status}/${period ? `${period.year}/${period.month}` : ''}`;

function Request({ request, cls, notifications }) {
	const location = useLocation();

	const [collapsed, set_collapsed] = useState(true);

	let rooms = get_rooms(request.schedule?.rooms).split('\n');
	if (rooms.length > 3) {
		if (collapsed) {
			rooms.length = 2;
			rooms = (
				<>
					{rooms.map((room) => (
						<span key={room}>{room}</span>
					))}
					<a
						href="#!"
						onClick={(e) => {
							e.preventDefault();
							set_collapsed(false);
						}}
					>
						See more
					</a>
				</>
			);
		} else {
			rooms = (
				<>
					{rooms.map((room) => (
						<span key={room}>{room}</span>
					))}
					<a
						href="#!"
						onClick={(e) => {
							e.preventDefault();
							set_collapsed(true);
						}}
					>
						See less
					</a>
				</>
			);
		}
	} else {
		rooms = rooms.map((room) => <span key={room}>{room}</span>);
	}

	return (
		<li className={`${cls} ${location.hash.includes(request._id) ? 'active' : ''} ${request.notifications > 0 ? 'unread' : ''}`}>
			<div className="info">
				<p className="subinfo">
					{request.schedule?.date} ({prettier(request.schedule?.times[0].start)} - {prettier(request.schedule?.times[2].end)})
				</p>
				<p>{request.requestInformation?.session_name}</p>
				<p className="rooms">{rooms}</p>
			</div>
			{request.result && <p className="status">{results_title[request.result]}</p>}
			<div className="actions">
				<a className="icon border arrow_right_requests" href={`#booking_${request._id}`}></a>
			</div>
		</li>
	);
}

function RequestsList({ status, year, month }) {
	const location = useLocation();
	const { list, get_list } = useContext(BookingsContext);

	useEffect(() => {
		const data = { status };
		if (status !== def_tab) {
			data.year = year;
			data.month = month;
		}
		get_list(data);
	}, [status, year, month]);

	if (!list) return <Loader />;

	return list.length > 0 ? (
		<ul className="list">
			{list.map((request, i) => {
				let cls = '';
				let next = list[i + 1];
				if (next && (location.hash.includes(next._id) || next.notifications > 0)) cls = 'borderless';

				return <Request key={i} cls={cls} request={request} />;
			})}
		</ul>
	) : (
		<p className="no_items">Currently, there are no {tabs[status].toLowerCase()} requests.</p>
	);
}

function Header({ status, month, year }) {
	//const { unseen_bookings } = useContext(NotificationsContenxt);

	return (
		<div className="header_block">
			<h1>Requests</h1>

			<div className="tabs">
				{Object.keys(tabs).map((tab) => (
					<Link to={`/dashboard/requests/${tab}`} className={tab === status ? 'active' : ''} key={tab}>
						{tabs[tab]}
						{/*tab === def_tab && unseen_bookings ? <span className="counter">{unseen_bookings}</span> : <></>*/}
					</Link>
				))}
			</div>

			{status !== def_tab && (
				<MonthPicker
					current={{
						year,
						month,
					}}
					get_link={(period) => get_link(status, period)}
					minimum={status === 'approved' && DateTime.now().toObject({ year: true, month: true })}
				/>
			)}
		</div>
	);
}

function RequestsView() {
	let { status = 'pending', year = DateTime.now().year, month = DateTime.now().month } = useParams();

	year = +year;
	month = +month;

	return (
		<section className="dashboard_list requests_list">
			<Header status={status} year={year} month={month} />
			<RequestsList status={status} year={year} month={month} />
		</section>
	);
}

export default RequestsView;
