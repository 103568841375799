import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';

import { DateTime } from 'luxon';

import AssetsContext from '#context/assets.jsx';
import ModalsContext from '#context/modals.jsx';
import MonthPicker from '#components/month_picker.jsx';
import BookingsContext from '#context/bookings.jsx';
import Loader from '#components/loader.jsx';

import psl_uuid from '#libs/psl_uuid.js';

import { prettier } from '#components/time_helpers.js';

const _type2name = new Map([
	['staff', 'Person'],
	['equipment', 'Equipment'],
]);

const Actions = ({ asset, handle_close, is_creating }) => (
	<div className={`actions ${is_creating ? 'create' : ''}`}>
		{is_creating ? (
			<></>
		) : (
			<button type="reset" onClick={handle_close}>
				Discard Changes
			</button>
		)}
		<button type="submit">{is_creating ? `Add ${_type2name.get(asset.type)}` : 'Save Changes'}</button>
	</div>
);

const AssetInformation = ({ asset, handle_change, set_asset, change }) => {
	const handle_change_ids = (e, index) => {
		const new_ids = [...asset.ids];
		new_ids[index].name = e.target.value;
		set_asset({ ...asset, ids: new_ids });
		change();
	};

	return (
		<div className="block">
			<div className="input">
				<label htmlFor="name">Name of {_type2name.get(asset.type)}*</label>
				<input type="text" name="name" value={asset.name} onChange={handle_change} required />
			</div>

			{asset.ids ? (
				<div className="add_item">
					<h3>Items</h3>
					{asset.ids.map((el, i) => (
						<div className="input" key={i}>
							<label htmlFor="name">Item #*</label>
							<input type="text" name="name" value={el.name} onChange={(e) => handle_change_ids(e, i)} required />
							{asset.ids.length === 1 ? (
								<></>
							) : (
								<a
									className="icon close_small"
									onClick={() => {
										asset.ids.splice(i, 1);
										set_asset({ ...asset, ids: asset.ids });
										change();
									}}
								/>
							)}
						</div>
					))}
					<a onClick={() => set_asset({ ...asset, ids: [...asset.ids, { _id: psl_uuid(8), name: '' }] })}>+ Add item</a>
				</div>
			) : (
				<></>
			)}
		</div>
	);
};

const Header = ({ asset, handle_close, handle_delete, is_creating }) => (
	<div className="header_block">
		<a className="icon border close" onClick={handle_close}></a>
		<h2>{is_creating ? `New ${_type2name.get(asset.type)}` : `Asset Information`}</h2>
		<div className="actions">
			<a className="icon border red delete_red" onClick={is_creating ? handle_close : handle_delete}></a>
		</div>
	</div>
);

const AssetCalendar = ({ asset }) => {
	const bookings_context = useContext(BookingsContext);
	const [year, set_year] = useState(DateTime.now().year);
	const [month, set_month] = useState(DateTime.now().month);

	const [list, set_list] = useState();

	useEffect(() => {
		set_list(undefined);
		(async () => {
			const assets = [];
			if (asset.ids) {
				asset.ids.forEach(({ _id }) => {
					assets.push(`${asset._id}_${_id}`);
				});
			} else {
				assets.push(asset._id);
			}

			set_list(
				(await bookings_context.get_asset_calendar({ year, month, assets }))
					.filter((el) => el.result !== 'cancelled')
					.sort((a, b) => {
						const a_timestamp = new Date(a.schedule.date).valueOf();
						const b_timestamp = new Date(b.schedule.date).valueOf();
						if (a_timestamp === b_timestamp) {
							return a.schedule.times[0].start - b.schedule.times[0].start;
						} else {
							return a_timestamp - b_timestamp;
						}
					})
			);
		})();
	}, [year, month]);

	const items = [];

	if (asset.ids) {
		asset.ids.forEach((item) =>
			items.push({
				_id: `${asset._id}_${item._id}`,
				name: item.name,
			})
		);
	} else {
		items.push(asset);
	}

	const lis = [];
	if (list) {
		if (items.length > 1) {
			lis.push(<div className="name title">ID</div>);
		}
		lis.push(<div className="date title">Date</div>);
		lis.push(<div className="session title">Session</div>);

		list.map((booking) => {
			items.forEach((item) => {
				if ((booking.assets || []).indexOf(item._id) === -1) return;
				if (items.length > 1) {
					lis.push(<div className="name">{item.name}</div>);
				}
				lis.push(
					<div className="date">
						{booking.schedule.date.split(',')[0]},&nbsp; {prettier(booking.schedule.times[0].start)}&nbsp;-&nbsp;
						{prettier(booking.schedule.times[2].end)}
					</div>
				);
				lis.push(
					<div className="session">
						<a href={`/booking/${booking._id}`} target="blank">
							{booking.requestInformation.session_name}
						</a>
					</div>
				);
			});
		});
	}

	return (
		<div className="asset_calendar">
			<div className="header">
				<h4>Assigned Schedule</h4>
				<MonthPicker
					current={{
						year,
						month,
					}}
					change={({ year, month }) => {
						set_year(year);
						set_month(month);
					}}
				/>
			</div>
			{!list ? (
				<Loader />
			) : (
				<div className={`list ${items.length > 1 ? 'items' : ''}`}>{list.length === 0 ? <span className="empty">No sessions</span> : lis}</div>
			)}
		</div>
	);
};

const AssetView = ({ data }) => {
	const navigate = useNavigate();

	const assets_context = useContext(AssetsContext);
	const modals_context = useContext(ModalsContext);

	const [is_creating, set_is_creating] = useState(false);
	const [asset, set_asset] = useState();
	const [changed, set_changed] = useState(false);

	const change = () => {
		if (!changed) set_changed(true);
	};

	useEffect(() => {
		if (data[0] === 'staff' || data[0] === 'equipment') {
			set_is_creating(true);
			set_changed(false);
			switch (data[0]) {
				case 'staff':
					set_asset({
						name: '',
						type: 'staff',
					});
					break;
				case 'equipment':
					set_asset({
						name: '',
						type: 'equipment',
						ids: [{ _id: psl_uuid(8), name: '' }],
					});
					break;
			}
			return;
		}

		(async () => {
			set_is_creating(false);
			set_changed(false);
			set_asset();
			const result = await assets_context.get(data[0]);
			set_asset(result);
		})();
	}, [data[0]]);

	if (!asset) return <Loader />;

	const handle_change = (e) => {
		const { name, value } = e.target;
		set_asset((asset) => ({ ...asset, [name]: value }));
		change();
	};

	const handle_close = () => {
		if (!changed) {
			navigate('#');
			return;
		}

		modals_context.show('asset_action', {
			action: 'leave',
			clb: () => navigate('#'),
		});
	};

	const handle_delete = () =>
		modals_context.show('asset_action', {
			action: 'delete',
			clb: () => {
				assets_context.api.delete(asset._id);
				navigate('#');
			},
		});

	const handle_submit = async (e) => {
		e.preventDefault();

		if (!changed) return;

		const new_asset = { ...asset };

		try {
			modals_context.show(
				'saved_changes',
				is_creating ? { text: `You’ve successfully added <b>“${new_asset.name}”</b> asset` } : { state: 'column' }
			);
			is_creating ? await assets_context.api.create(new_asset) : await assets_context.api.update(new_asset);
			navigate('#');
		} catch (e) {
			console.error(e);
		}
	};

	return (
		<div className="room_item">
			<Header asset={asset} handle_close={handle_close} handle_delete={handle_delete} is_creating={is_creating} />
			<form onSubmit={handle_submit}>
				<AssetInformation asset={asset} handle_change={handle_change} set_asset={set_asset} change={change} />
				{changed ? <Actions asset={asset} handle_close={handle_close} is_creating={is_creating} /> : <></>}
			</form>
			{is_creating ? <></> : <AssetCalendar asset={asset} />}
		</div>
	);
};

export default AssetView;
